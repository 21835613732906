<template>
  <div class="data">
    <v-header />
    <div style="height: 70px;"></div>
    <div class="tab">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>招标公告</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="news">
      <div class="news-left">
        <div class="purchase">
          <div class="item">
            <div class="list" v-for="list in 11" :key="list">
              <p class="title">湖南省湘西自治州保靖县找商住房合作建筑商</p>
              <el-tag effect="dark" size="medium">直采</el-tag>
              <el-tag type="danger" size="medium">103.00万</el-tag>
              <el-tag size="medium">江苏 南京</el-tag>
              <p class="time">2023-09-13</p>
            </div>

            <div class="list">
              <p class="title">湖南省湘西自治州保靖县找商住房合作建筑商</p>
              <el-tag effect="dark" size="medium">直采</el-tag>
              <el-tag type="danger" size="medium">103.00万</el-tag>
              <el-tag size="medium">江苏 南京</el-tag>
            </div>
          </div>
        </div>
      </div>
      <div class="news-right">
        <h3>平台自营相关投标方案</h3>
        <ul>
          <li v-for="(list,index) in 10" :key="index">
            <p
              :class="index == 0 ? 'code-1' : index == 1 ? 'code-2' : index == 2 ? 'code-3' : 'code'"
              class="code"
            >{{index + 1}}</p>
            <a href>车辆维修服务投标方案（技术标 382页）</a>
          </li>
        </ul>
      </div>
    </div>
     <v-footer />
  </div>
</template>

<script>
import header from "@/components/header";
import footer from "@/components/footer"
export default {
  components: {
    "v-header": header,
    "v-footer": footer
  }
};
</script>

<style lang="less" scoped>
.data {
  //   min-height: 100vh;
  background: #f5f6f7;
}
.news {
  width: 1200px;
  margin: auto;
overflow: hidden;
  &-left {
    width: 73%;
    float: left;
    .purchase {
      width: 100%;
      margin: auto;
      .item {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .list {
          width: 100%;
          height: 95px;
          background: #fff;
          padding: 20px 20px;
          position: relative;
          margin-bottom: 3px;
          cursor: pointer;
          .title {
            height: 60px;
            font-size: 18px;
            font-weight: 800;
          }
          .el-tag {
            margin-right: 5px;
            margin-top: 10px;
          }
          .time {
            position: absolute;
            right: 15px;
            bottom: 15px;
            font-size: 15px;
            color: #000;
            opacity: 0.6;
            font-weight: 800;
          }
        }
      }
    }
    .datas {
      width: 100%;
      height: auto;
      background: #fff;
      padding-bottom: 20px;
      h1 {
        width: 95%;
        height: 50px;
        line-height: 70px;
        margin: auto;
        margin-bottom: 10px;
        font-size: 20px;
      }
      .box {
        width: 92%;
        height: 25px;
        line-height: 25px;
        margin: auto;
        display: flex;
        background: #f5f6f7;
        padding: 10px;
        .list {
          width: auto;
          color: #000;
          opacity: 0.7;
          margin-right: 25px;
          font-size: 14px;
        }
      }
    }
    .content {
      width: 100%;
      min-height: 300px;
      background: #fff;
      margin-top: 10px;
      .con {
        width: 95%;
        margin: auto;
        padding-top: 20px;
      }
    }
  }
  &-right {
    width: 26%;
    // min-height: 100px;
    background: #fff;
    float: right;
    h3 {
      width: 100%;
      height: 50px;
      position: relative;
      text-indent: 20px;
      font-size: 17px;
      border-bottom: 1px solid #f5f6f7;
      display: flex;
      align-items: center;
      &::after {
        position: absolute;
        content: "";
        width: 5px;
        height: 15px;
        background: #3363ff;
        left: 0;
      }
    }
    ul {
      padding-top: 10px;
      padding-bottom: 10px;
      li {
        height: 35px;
        line-height: 35px;
        list-style: none;
        display: flex;
        align-items: center;
        margin-bottom: 5px;
        .code {
          width: 20px;
          height: 20px;
          line-height: 20px;
          color: #000;
          background: #f3f3f3;
          text-align: center;
          border-radius: 3px;
          margin-left: 15px;
          margin-right: 10px;
          font-size: 11px;
        }
        a {
          text-decoration: none;
          color: #000;
          opacity: 0.8;
          font-size: 14px;
        }
        .code-1 {
          color: #fff;
          background: #ff4141;
        }
        .code-2 {
          color: #fff;
          background: #ff7b41;
        }
        .code-3 {
          color: #fff;
          background: #ffc641;
        }
      }
    }
  }
}
</style>